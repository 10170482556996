var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.items,"items-per-page":15,"loading":_vm.loading,"loading-text":_vm.$t('loading_text'),"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"itemClass":_vm.itemClass,"hide-default-header":""},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":[function($event){_vm.sortBy=$event},function($event){return _vm.logActivity('sort')}],"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event},"click:row":function($event){return _vm.$emit('click:row', $event)},"update:page":function($event){return _vm.logActivity('paginate')}},scopedSlots:_vm._u([_vm._l((Object.keys(_vm.$scopedSlots)),function(slot){return {key:slot,fn:function(slotScope){return [_vm._t(slot,null,null,slotScope)]}}}),{key:"header",fn:function(ref){
var props = ref.props;
return [_c('thead',{staticClass:"v-data-table-header"},[_c('tr',_vm._l((props.headers),function(header){return _c('th',{key:header.value,staticClass:"text-start sortable",class:{ 'has-description': header.description },attrs:{"role":"columnheader","scope":"col","aria-sort":"none"}},[(header.description)?_c('v-tooltip',{attrs:{"top":"","max-width":"250px","color":"#007C82"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-1",attrs:{"color":"#666666"}},'v-icon',attrs,false),on),[_vm._v(" mdi-information-outline ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(header.description))])]):_vm._e(),_c('span',[_vm._v(_vm._s(header.text))]),(header.sortable)?_c('img',{staticClass:"sorting-icon",attrs:{"src":require("@/assets/imgs/table-sorting.svg")},on:{"click":function($event){return _vm.sort(header.value)}}}):_vm._e()],1)}),0)])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(item.readyToDelete)?[_c('v-btn',{staticClass:"\n            ma-auto\n            text-uppercase\n            bold\n            d-inline-flex\n            align-center\n            text-center\n            delete\n            ml-2\n            mr-2\n          ",attrs:{"hide-details":"","height":"46","width":"188"}},[_vm._v(" "+_vm._s(_vm.$t("delete"))+" ")]),_c('v-btn',{staticClass:"\n            ma-auto\n            text-uppercase\n            bold\n            d-inline-flex\n            align-center\n            text-center\n            ml-2\n            cancel\n          ",attrs:{"hide-details":"","height":"46","width":"188"},on:{"click":function($event){_vm.commentDialog = true}}},[_vm._v(" "+_vm._s(_vm.$t("cancel"))+" ")])]:[_c('div',{staticClass:"w-100 text-right"},[_c('span',{staticClass:"icon"},[_c('img',{staticClass:"pen-icon",attrs:{"src":require("@/assets/imgs/pen.svg")}})]),_c('span',{staticClass:"icon ml-4 mr-3",on:{"click":function($event){return _vm.deleteItem(item, $event)}}},[_c('img',{staticClass:"recycle-bin-icon ",attrs:{"src":require("@/assets/imgs/recycle-bin.svg")}})])])]]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div class="admin-users" v-if="$store.getters['auth/hasAdminTab']">
    <AdminTab :currentTab="1" />

    <admin-title />

    <v-container>
      <v-row class="justify-space-between">
        <v-col cols="2" class="pt-0 sub-title d-flex align-center"
          >{{ $t("hcp_users") }}
        </v-col>
        <v-col cols="3" class="pt-0">
          <v-btn
            hide-details
            class="
              ma-auto
              text-uppercase
              bold
              d-flex
              align-center
              text-center
              float-right
              bg-theme-primary
              new-hcp
            "
            height="46"
            width="149"
            @click="commentDialog = true"
          >
            {{ $t("new_hcp") }}
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <Table
            :headers="headers"
            :items="items"
            @deleteItem="deleteItem"
            ref="usersTable"
          >
            <template v-slot:item.name="{ item }">
              <template v-if="item.readyToDelete">
                <span class="bold">
                  {{ $t("are_you_sure_you_want_to_delete") }}
                  <span>Francisco Salazar?</span>
                </span>
              </template>
              <template v-else>
                {{ item.name }}
              </template>
            </template>

            <template v-slot:item.role="{ item }">
              <template v-if="item.readyToDelete"></template>
              <template v-else>
                {{ item.role }}
              </template>
            </template>
          </Table>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import AdminTab from "@/components/views/admin/AdminTab.vue";
import AdminTitle from "../../components/views/admin/AdminTitle.vue";
import Table from "@/components/Table.vue";

export default {
  name: "AdminUsers",

  data() {
    return {
      headers: [
        {
          text: this.$t("name"),
          value: "name",
          width: "25%",
        },
        {
          text: this.$t("role"),
          value: "role",
          width: "15%",
        },
        {
          value: "actions",
          width: "40%",
        },
      ],
      loading: false,
      items: [
        {
          name: "Andrea García",
          role: "user",
        },
        {
          name: "Daniel Salazar Hernández",
          role: "admin",
        },
        {
          name: "Francisco Salazar",
          role: "user",
        },
        {
          name: "Francisco Javier García",
          role: "user",
        },
        {
          name: "María Guadalupe Guerrero",
          role: "admin",
        },
      ],
    };
  },

  beforeCreate() {
    if (!this.$store.getters['auth/hasAdminTab']) {
      this.$router.push({name: 'my-account'});
    }
  },

  methods: {
    deleteItem(e) {
      // increase the width of the first td
      this.headers[0].width = "60%";
      this.headers[1].width = "0%";
      this.headers[2].width = "40%";
      // insert readyToDelete to this row
      this.items[e.index].readyToDelete = true;
      this.$refs.usersTable.$forceUpdate();
    },
  },

  components: { AdminTab, AdminTitle, Table },
};
</script>

<style lang="scss" src="@/assets/sass/views/admin/users.scss"></style>
